import React from "react";
import styled from "styled-components";

import img1 from "../assets/Images/1.webp";
import img2 from "../assets/Images/2.webp";
import img3 from "../assets/Images/3.webp";
import img4 from "../assets/Images/hookii_logo.webp";

const Section = styled.section`
  position: relative;
  height: auto;
  width: auto;
  display: flex;
  
  margin: 0 auto;

  @media (max-width: 48em) {
    width: 100vw;
  }
  @media (max-width: 30em) {
    width: 100vw;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Cambria";
  font-weight: 300;
      
  text-shadow: 1px 1px 1px ${(props) => props.theme.text};

  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 5;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;
const Left = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 600;
  position: relative;
  z-index: 5;
  padding: 8%;
  padding-right: 15%;
  text-align: justify; // Justify the text within the Left container
        
  @media (max-width: 64em) {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    width: 70%;
  }
`;
const Right = styled.div`
  width: 50%;
  position: relative;
  display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2%;
    padding-top: 2%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5%;    
  }

  .small-img-1 {
    width: 40%;
    position: absolute;
    right: 95%;
    bottom: 10%;
    border-radius: 5%;
    
  }
  .small-img-2 {
    width: 40%;
    position: absolute;
    left: 80%;
    bottom: 30%;
    display: none; // hides picture
  }

  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100vh;
      object-fit: cover;
    }
    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 30%;
      height: auto;
      left: 60%;
      bottom: 20%;
    }
  }
`;

const About = () => {
  return (
    <Section id="fixed-target" className="about">
      <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      >
        <img src={img4} alt="Hookii Logo" style={{ width: '50%' }} />        
      </Title>
      <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
      <h2>Hookii: pažangiausios technologijos Jūsų namams</h2>
        <br>
        </br>
        <p>Hookii – tai robotinių vejapjovių įmonė, įkurta patyrusių robotikos specialistų, turinčių dešimtmečio patirtį. Hookii misija - gaminti pažangius robotus prieinamus visiems vartotojams, kad šios technologijos palengvintų kasdieninę buitį. Tikslas – tapti pasauliniu lyderiu dirbtinio intelekto srityje.</p>
        <br>
        </br>
        <h3>Hookii išsiskirtinumas</h3>
        <br>
        </br>
        <ul>
          <li>Savo srities Ekspertai: Roboto kūrėjų komanda turi daugiau nei 10 metų patirties robotikos srityje.</li>
          <li>Dėmesys R&D: 70% komandos dirba mokslinių tyrimų ir plėtros srityje. Bendrovė skiria tokį pat resursų kiekį investicijoms į naujas technologijas.</li>
          <li>Naujos kartos technologija: Neomow X yra jau trečios kartos įmonės gaminamas vejos robotas, pasižymintis pažangiausiomis funkcijomis.</li>
          <li>Požiūris orientuotas į vartotoją: Hookii prioritetą teikia naudotojų patirčiai ir praktiškumui, užtikrinant, kad pažangios technologijos teiktų malonią patirtį.</li>
          <li>Gaminama produkcija turi tikrai patrauklią kainą!</li>
        </ul>
        <br>
        </br>
        <p>Hookii puikiai suderina technines galimybes ir vartotojų lūkesčius, kad ateities robotika pasiektų Jūsų namus.</p>
      </Left>
      <Right>
        <img src={img1} alt="About Us" />
        <img
          data-scroll
          data-scroll-speed="5"
          src={img2}
          className="small-img-1"
          alt="About Us"
        />
        <img
          data-scroll
          data-scroll-speed="-2"
          src={img3}
          alt="About Us"
          className="small-img-2"
        />
      </Right>
    </Section>
  );
};

export default About;

// Import statements for necessary modules and components
import React from "react"; 
// React: Core library for building user interfaces

import styled from "styled-components";  
// styled-components: Library for writing CSS-in-JS for styling React components

import { motion } from "framer-motion";
// motion: Component from Framer Motion library for creating animations

import neomowLogo from '../assets/Svgs/neomow_x.png'; // Import the PNG

// Styled components for defining the structure and styles of the loader 

// Container: The main container for the loader component
const Container = styled(motion.div)` 
  position: absolute; // Position the container relative to the viewport
  top: 0;              // Position the top edge at the top of the viewport
  left: 0;             // Position the left edge at the left of the viewport
  right: 0;            // Position the right edge at the right of the viewport
  bottom: 0;           // Position the bottom edge at the bottom of the viewport

  touch-action: none; // Disable touch events on the loader container 
  overflow: hidden;   // Hide any content that overflows the container
  width: 100vw;       // Set the width to 100% of the viewport width
  height: 100vh;      // Set the height to 100% of the viewport height
  z-index: 6;         // Ensure the loader is above other content (higher z-index)

  // Flexbox properties for centering content within the container
  display: flex;       
  flex-direction: column; // Arrange items vertically
  justify-content: center; // Center vertically
  align-items: center;   // Center horizontally

  background-color: ${(props) => props.theme.body}; 
    // Background color from the theme
  color: ${(props) => props.theme.text};           
    // Text color from the theme

  // Styles for any SVG element within the container
  svg {
    width: 10vw;         // Set the width to 10% of the viewport width
    height: auto;        // Automatically adjust the height to maintain aspect ratio
    overflow: visible;    // Allow SVG content to overflow the container
    stroke-linejoin: round; // Set the style of line joins to be rounded
    stroke-linecap: round;  // Set the style of line caps to be rounded

    // Styles for <g> (group) elements within the SVG
    g {
      path {           // Styles for <path> elements within the SVG groups
        stroke: ${(props) => props.theme.text}; 
          // Set the stroke color to the theme's text color
      }
    }
  }

  // Media query for smaller screens (max-width: 48em)
  @media (max-width: 48em) {
    svg {           // Adjust the SVG width for smaller screens
      width: 20vw;  // Set the width to 20% of the viewport width
    }
  }
`;

// Text: Styled component for the text element within the loader
const Text = styled(motion.span)`
  font-size: ${(props) => props.theme.fontxl}; 
    // Font size from the theme
  color: ${(props) => props.theme.text};       
    // Text color from the theme
  padding-top: 0.5rem;                
    // Add padding to the top of the text

  // Media query for smaller screens
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg}; 
      // Adjust the font size for smaller screens
  }
`;

// Animation variants for the text element
const textVariants = {
  hidden: { // Initial state - hidden
    opacity: 0, // Set opacity to 0 (invisible)
  },
  visible: { // Visible state
    opacity: 1, // Set opacity to 1 (fully visible)
    transition: { // Define the transition properties for the animation
      duration: 1,        // Duration of the animation (1 second)
      yoyo: Infinity,     // Make the animation repeat infinitely
      ease: "easeInOut", // Set the easing function for a smooth transition
    },
  },
};

// Animation variants for the SVG path element
const pathVariants = {
  hidden: { // Initial state - hidden
    opacity: 0,      // Set opacity to 0 (invisible)
    pathLength: 0,   // Set the path length to 0, making the path invisible
  },
  visible: { // Visible state
    opacity: 1,      // Set opacity to 1 (fully visible)
    pathLength: 1,   // Set the path length to 1, making the full path visible
    transition: { // Define the transition properties
      duration: 2,        // Duration of the animation (2 seconds)
      ease: "easeInOut", // Set the easing function for a smooth transition
    },
  },
};

// Loader: The main functional component for the loader
const Loader = () => {
  return (
    <Container
      initial={{ // Define the initial state of the Container
        y: 0,       // Set the initial vertical position to 0
        opacity: 1,  // Set the initial opacity to 1 (fully visible)
      }}
      exit={{   // Define the exit state of the Container (when it's unmounted)
        y: "100%", // Move the Container down by 100% (off-screen)
        opacity: 0,  // Set opacity to 0 (invisible)
      }}
      transition={{ // Define the transition properties for the exit animation
        duration: 2,  // Duration of the animation (2 seconds)
      }}
    >
      <img src={neomowLogo} alt="Neomow X Logo" style={{ width: '8%' }} /> 
      <Text 
        variants={textVariants} // Apply the text animation variants
        initial="hidden"      // Set the initial state to hidden
        animate="visible"     // Animate to the visible state
      >
        TERMOinžinerija pristato 
        {/* Text content for the loader */}
      </Text>
    </Container>
  );
};

// Export the Loader component as the default export
export default Loader; 
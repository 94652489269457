import React from "react";
import styled from "styled-components";

import lite from "../assets/Images/pirkti/lite.webp";
import x from "../assets/Images/pirkti/x.webp";
import pro from "../assets/Images/pirkti/pro.webp";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #48735F;
  z-index: 10;
  padding-top: 2%;
  padding-bottom: 2%;

  @media (max-width: 64em) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Cambria";
  font-weight: bold;
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 11;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 2rem; /* Equal space between columns */
  justify-items: center; /* Center each column's content */
  align-items: start; /* Align items at the start */

  @media (max-width: 64em) {
    grid-template-columns: 1fr; /* 1 column on mobile */
    gap: 1.5rem;
  }

  @media (max-width: 48em) {
    gap: 1rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: #f2f1e9;
  border-radius: 20px;
  padding: 1rem;
  max-width: 100%; /* Prevent overflow */

  @media (max-width: 64em) {
    
  }

  @media (max-width: 48em) {
    
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 300px; /* Limit the maximum width for responsiveness */
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    max-width: 250px;
  }

  @media (max-width: 48em) {
    max-width: 200px;
  }
`;

const Image = styled.img`
  width: 100%; /* Ensure image fits within container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
  border-radius: 5%;
  margin-bottom: 1rem;

  @media (max-width: 64em) {
    margin-bottom: 0.75rem;
  }

  @media (max-width: 48em) {
    margin-bottom: 0.5rem;
  }
`;

const TextContainer = styled.div`
  h2 {
    font-size: ${(props) => props.theme.fontxl};
    margin-bottom: 1rem;

    @media (max-width: 64em) {
      font-size: ${(props) => props.theme.fontlg};
    }

    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontmd};
    }
  }

  ul {
    font-size: ${(props) => props.theme.fontmd};
    list-style-type: disc;
    padding-left: 1.5rem; /* Indent bullet points */
    text-align: left; /* Align text for bullet points */
    
    @media (max-width: 64em) {
      padding-left: 1rem;
      font-size: ${(props) => props.theme.fontsm};
    }

    @media (max-width: 48em) {
      padding-left: 0.75rem;
      font-size: ${(props) => props.theme.fontxs};
    }
  }
`;

const Product = ({ img, title = "", heading = "", description = [] }) => {
  return (
    <Item>
      <ImageContainer>
        <Image src={img} alt={title} />
      </ImageContainer>
      <TextContainer>
        <h2>{heading}</h2>
        <ul>
          {description.map((desc, index) => (
            <li key={index}>{desc}</li>
          ))}
        </ul>
      </TextContainer>
    </Item>
  );
};

const NewArrival = () => {
  return (
    <Section id="new-arrival">
      <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      >
        Žoliapjovių versijos
      </Title>
      <Container>
        <Product
          img={lite}
          title="Pirmas title"
          heading="Neomow X Lite"
          description={[
            "Navigacijos sistema: 3D LiDAR SLAM & Vision",
            "Pozicionavimas ir navigacija: LiDAR/Vision/Bamperis",
            "Kliūčių vengimas: U formos & Kilpinis",
            "Maršruto planavimas: Taip",
            "Nepertraukiamas pjovimas: Taip",
            "Daugiazonė ir Neleidžiamų zonų funkcija: Taip",
            <strong>Pjovimo plotas: Iki 2000m² (iki 20 arų)</strong>,
            "Pjovimo plotas vienu įkrovimu: 600m²",
            "Pjovimo greitis: 0,2-0,6m/s",
            "Pjovimo aukštis: 30mm-85mm",
            "Pjovimo plotis: 280mm",
            "Maksimalus nuolydis: 24°",
            "Peilių skaičius: 5",
            "Siauriausia praeitis: > 77cm",
            "Automatinis įkrovimas: Taip",
            "WiFi: 2.4GHz-2.4835GHz, 5.1GHz-5.85GHz",
            "Bluetooth: Taip",
            "4G: Taip",
            "GPS: Taip",
            "Programinės įrangos atnaujinimas: Taip"
          ]}
        />
        <Product
          img={x}
          title="Antras title"
          heading="Neomow X"
          description={[
            "Navigacijos sistema: 3D LiDAR SLAM & Vision",
            "Pozicionavimas ir navigacija: LiDAR/Vision/Bamperis",
            "Kliūčių vengimas: U formos & Kilpinis",
            "Maršruto planavimas: Taip",
            "Nepertraukiamas pjovimas: Taip",
            "Daugiazonė ir Neleidžiamų zonų funkcija: Taip",
            <strong>Pjovimo plotas: Iki 4000m² (iki 40 arų)</strong>,
            "Pjovimo plotas vienu įkrovimu: 600m²",
            "Pjovimo greitis: 0,2-0,6m/s",
            "Pjovimo aukštis: 30mm-85mm",
            "Pjovimo plotis: 280mm",
            "Maksimalus nuolydis: 24°",
            "Peilių skaičius: 5",
            "Siauriausia praeitis: > 77cm",
            "Automatinis įkrovimas: Taip",
            "WiFi: 2.4GHz-2.4835GHz, 5.1GHz-5.85GHz",
            "Bluetooth: Taip",
            "4G: Taip",
            "GPS: Taip",
            "Programinės įrangos atnaujinimas: Taip"
          ]}
        />
        <Product
          img={pro}
          title="Trecias title"
          heading="Neomow X Pro"
          description={[
            "Navigacijos sistema: 3D LiDAR SLAM & Vision",
            "Pozicionavimas ir navigacija: LiDAR/Vision/Bamperis",
            "Kliūčių vengimas: U formos & Kilpinis",
            "Maršruto planavimas: Taip",
            "Nepertraukiamas pjovimas: Taip",
            "Daugiazonė ir Neleidžiamų zonų funkcija: Taip",
            <strong>Pjovimo plotas: Iki 6000m² (iki 60 arų)</strong>,
            "Pjovimo plotas vienu įkrovimu: 900m²",
            "Pjovimo greitis: 0,2-0,6m/s",
            "Pjovimo aukštis: 30mm-85mm",
            "Pjovimo plotis: 280mm",
            "Maksimalus nuolydis: 24°",
            "Peilių skaičius: 5",
            "Siauriausia praeitis: > 77cm",
            "Automatinis įkrovimas: Taip",
            "WiFi: 2.4GHz-2.4835GHz, 5.1GHz-5.85GHz",
            "Bluetooth: Taip",
            "4G: Taip",
            "GPS: Taip",
            "Programinės įrangos atnaujinimas: Taip"
          ]}
        />
      </Container>
    </Section>
  );
};

export default NewArrival;

import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import img1 from "../assets/Images/specs/1.gif";
import img2 from "../assets/Images/specs/2.gif";
import img3 from "../assets/Images/specs/3.avif";
import img4 from "../assets/Images/specs/4.gif";
import img5 from "../assets/Images/specs/5.webp";
import img6 from "../assets/Images/specs/6.gif";
import img7 from "../assets/Images/specs/7.webp";
import img8 from "../assets/Images/specs/8.webp";
import img9 from "../assets/Images/specs/9.gif";
import img10 from "../assets/Images/specs/10.webp";
import img11 from "../assets/Images/specs/11.webp";
import img12 from "../assets/Images/specs/12.gif";
import img13 from "../assets/Images/specs/13.avif";
import img14 from "../assets/Images/specs/14.avif";
import img15 from "../assets/Images/specs/15.gif";
import img16 from "../assets/Images/specs/16.gif";
import img17 from "../assets/Images/specs/17.gif";
import img18 from "../assets/Images/specs/18.webp";

const Section = styled.section`
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Cambria";
  font-weight: bold;
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 11;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Left = styled.div`
  width: 30%; 
  background-color: #48735F;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5rem;

  @media (max-width: 64em) {
    width: 15%; 
  }
  @media (max-width: 48em) {
    width: 10%; 
  }
  
  p {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 300;
    width: 80%;
    margin: 0 auto;
    padding: 10vh 0px 0px 0px;
    text-align: justify;
  }

  @media (max-width: 64em) {
    p {
      font-size: ${(props) => props.theme.fontmd};
      display: none !important;
    }
  }
`;

const Right = styled.div`
  width: 70%;
  background-color: ${(props) => props.theme.white};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 2rem;
  padding: 2rem; */

  @media (max-width: 64em) {
    grid-template-columns: 1fr;
    margin: 0 auto;  
  }
`;

const Item = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  h2 {
    font-size: ${(props) => props.theme.fontlg};
    margin-bottom: 1rem;
    text-align: center;
  }

  img, video {
    width: auto;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  p {
    font-size: ${(props) => props.theme.fontmd};
    text-align: center;
    padding-inline-start: 5em;
    padding-inline-end: 5em;
    text-align: justify;   
  }

  @media (max-width: 48em) {
    h2 {
      font-size: ${(props) => props.theme.fontmd};
    }
    p {
      font-size: ${(props) => props.theme.fontsm};      
    }
    img, video {
      height: 150px;
    }
  }
`;

const Product = ({ img, title = "", description = "" }) => {
  return (
    <Item
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: false, amount: 0.3 }}
    >          
      <h2>{title}</h2>
      <img src={img} alt={title} /> 
      <p>{description}</p>
    </Item>
  );
};

const Shop = () => {
  return (
    <Section id="shop">
      <Title data-scroll data-scroll-speed="-1">
        Neomow X
      </Title>
      <Left>
        <p>
          Neomow X yra robotizuota vejapjovė, kuri naudoja LiDAR SLAM navigaciją,
          kad galėtų tiksliai pjauti veją be perimetro laidų. Ji pasižymi išmaniuoju
          kliūčių vengimu, kelių zonų valdymu ir galinga priekinių varomųjų ratų
          sistema, leidžiančia įveikti įvairias vietoves ir šlaitus.
          <br />
          <br />
          Neomow X valdoma ir stebima naudojant patogią programėlę. Naudotojai
          gali pritaikyti pjovimo grafikus, aukštį ir sekti vejapjovės buvimo
          vietą. Neomow X turi savaiminio įkrovimo funkciją, lietaus jutiklio
          technologiją ir apsaugos nuo vagystės funkcijas, užtikrinančias
          didesnį patogumą ir saugumą.
          <br />
          <br />
          Dėl pažangių funkcijų ir išmaniosios konstrukcijos Neomow X užtikrins,
          kad vejos priežiūra nesukeltų rūpesčių ir būtų efektyvi.
        </p>
      </Left>
      <Right>
        <Product img={img1} title="Be perimetrinio laido" description="Neomow X veikia sklandžiai be poreikio tiesti perimetrinius laidus. Galite valdyti jį per programėlę, greitai nustatydami virtualias ribas, draudžiamas zonas ir takus. Pažangi LiDAR SLAM navigacijos sistema užtikrina tikslų pozicionavimą ir ribų laikymąsi, supaprastindama jūsų vejos priežiūrą." />
        <Product img={img2} title="LiDAR SLAM navigacija" description="Neomow X naudoja moderniausią LiDAR SLAM navigaciją, kurdamas tikslius 3D žemėlapius, leidžiančius tiksliai naršyti po jūsų veją. Skirtingai nei RTK technologija su nestabiliu signalu ar regėjimu pagrįsti vejapjovės, kurioms sunkiau prisitaikyti, Neomow X puikiai veikia visomis vejos sąlygomis ir užtikrina aukščiausią našumą." />
        <Product img={img3} title="Tikslus pozicionavimas" description="Neomow X minimalizuoja aplinkos sąlygų įtaką, su kuria dažnai susiduriama dirbant gyvenamųjų namų vejų priežiūroje. Naudodamas pažangias jutiklių sistemas ir programinę įrangą, šis modelis gali tiksliai nustatyti ir išvengti kliūčių, nepriklausomai nuo to, ar tai būtų augalai, medžių kamienai ar net mažos statybos šiukšlės." />
        <Product img={img4} title="Vejos veiksmai" description="Neomow X teikia įvairius pjovimo režimus, atitinkančius skirtingus vejos priežiūros poreikius. Nuo reguliarių pjovimų iki pritaikytų grafikų ir intensyvių priežiūros režimų, ši vejapjovė leidžia pasirinkti tinkamiausią sprendimą jūsų vejai." />
        <Product img={img5} title="Kelių zonų valdymas" description="Daugiafunkcė Neomow X gali tvarkyti kelias vejos zonas, efektyviai ir be rūpesčių prižiūrėdama įvairias plotus jūsų kieme. Tai ideali pasirinkimo galimybė, jei turite atskiras vejos zonas ar kelias vejas, kurias reikia tvarkyti." />
        <Product img={img6} title="Šlaito valdymas" description="Neomow X gali tvarkyti šlaitus ir nelygius paviršius, naudodama pažangią priekinių varomųjų ratų sistemą. Tai užtikrina, kad jūsų vejos priežiūra būtų nuosekli ir kokybiška, nepriklausomai nuo jūsų sodo reljefo." />
        <Product img={img7} title="Savaiminis įkrovimas" description="Ši vejapjovė turi savaiminio įkrovimo funkciją, kuri leidžia jai automatiškai grįžti į įkrovimo stotelę, kai baterija yra mažai. Tai užtikrina, kad jūsų vejapjovė visada bus pasirengusi darbui, o jūs nereikės jaudintis dėl energijos." />
        <Product img={img8} title="Lietaus jutiklis" description="Neomow X yra aprūpinta lietaus jutikliu, kuris nustato kritulius ir sustabdo vejapjovės darbą, kai oras tampa nepalankus. Tai apsaugo nuo galimų pažeidimų ir užtikrina, kad jūsų vejapjovė veiktų optimaliomis sąlygomis." />
        <Product img={img9} title="Apsauga nuo vagystės" description="Apsaugos nuo vagystės funkcijos suteikia papildomą saugumą, užtikrindamos, kad jūsų Neomow X būtų apsaugota nuo neteisėto pašalinimo. Tai apima įmontuotus įspėjimus ir sistemą, leidžiančią stebėti vejos priežiūrą realiuoju laiku." />
        <Product img={img10} title="Išmanus valdymas" description="Naudodamiesi mobiliosios programėlės pagalba, galite lengvai valdyti Neomow X, nustatyti pjovimo grafiką, reguliuoti aukštį ir sekti vejapjovės būseną. Tai suteikia jums patogumą ir kontrolę, kad galėtumėte pritaikyti vejos priežiūrą pagal savo poreikius." />
        <Product img={img11} title="Priežiūros intervalai" description="Neomow X siūlo įvairius priežiūros intervalus, kurie leidžia pasirinkti tinkamą intervalą pagal jūsų vejos poreikius. Tai gali būti kasdienis, kas savaitinis ar net pritaikytas intervalas, atsižvelgiant į jūsų vejos augimo greitį ir kitus veiksnius." />
        <Product img={img12} title="Patikima kokybė" description="Neomow X yra pagaminta iš aukščiausios kokybės medžiagų ir prietaisų, kurie užtikrina ilgaamžiškumą ir patikimumą. Dėl savo patvarumo ir inovatyvių technologijų ši vejapjovė yra patikima investicija į jūsų vejos priežiūrą." />
        <Product img={img13} title="Pažangi programinė įranga" description="Pažangi programinė įranga, įdiegta Neomow X, suteikia galimybę nuolat atnaujinti funkcijas ir optimizuoti veikimą. Tai užtikrina, kad jūsų vejapjovė visada būtų prisitaikiusi prie naujausių technologijų ir vejos priežiūros tendencijų." />
        <Product img={img14} title="Ekonomiškas energijos vartojimas" description="Neomow X yra sukurta taip, kad būtų ekonomiška energijos vartojimo atžvilgiu. Efektyvi baterija ir energijos taupymo funkcijos padeda sumažinti eksploatacijos sąnaudas ir padidinti vejos priežiūros efektyvumą." />
        <Product img={img15} title="Tylus veikimas" description="Ši vejapjovė dirba labai tyliai, todėl ji netrukdys jūsų kasdieniam gyvenimui ar aplinkiniams. Tai idealus pasirinkimas, jei vertinate ramybę ir tylą, net kai vejos priežiūra vyksta." />
        <Product img={img16} title="Saugumo funkcijos" description="Neomow X yra aprūpinta įvairiomis saugumo funkcijomis, užtikrinančiomis, kad vejapjovė būtų saugi naudoti. Tai apima automatinį sustabdymą, kai aptinkamas pavojus, ir išmaniųjų jutiklių sistemą, kuri nuolat stebi aplinką." />
        <Product img={img17} title="Patogus naudojimas" description="Ši vejapjovė yra sukurta taip, kad būtų lengvai naudojama ir valdoma. Intuityvi programėlė ir paprasti nustatymai leidžia greitai pradėti darbą ir pritaikyti vejos priežiūrą pagal jūsų poreikius." />
        <Product img={img18} title="Visos sezonų savybės" description="Neomow X yra tinkama naudoti visais sezonais, nepriklausomai nuo oro sąlygų. Ji sugeba veikti tiek šaltu, tiek šiltu oru, todėl galėsite mėgautis puikiai prižiūrėta veja visus metus." />
      </Right>
    </Section>
  );
};

export default Shop;
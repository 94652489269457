import React from "react";
import styled from "styled-components";

const Section = styled.section`
  min-height: 30vh;
  width: 80vw;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  @media (max-width: 48em) {
    width: 90vw;
  }
`;
const Container = styled.div`
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 64em) {
    justify-content: center;
  }
`;

const BannerComponent = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Cambria";
  color: ${(props) => props.theme.text};
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
  
  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 64em) {
    margin: 1rem 0;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    margin: 0.5rem 0;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  span {
    display: block;
    background-color: #48735F;
    padding: 1rem 2rem;
    border-radius: 20px; 
  }
`;

const Banner = () => {
  return (
    <Section>
      <Container id="up">
        <BannerComponent>
        <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-8"
            data-scroll-target="#up"
          >
            Jau galite užsisakyti{" "}
          </span>
        </BannerComponent>        
      </Container>
    </Section>
  );
};

export default Banner;

import React from 'react';
import styled from 'styled-components';
import back from "../assets/Images/back.webp";

// Styled components for the section
const Section = styled.section`
  height: 70vh; /* Set the height to 70% of the viewport height */
  width: 100vw;
  position: relative;
  background: url(${back}) /* no-repeat center center fixed */; /* Fixed background for parallax effect */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any overflow due to the rounded corners of the overlay */
  flex-direction: column; /* Allow vertical stacking of elements */
  text-align: center; /* Center text within the section */
`;

// Styled Button with the new effect
const Button = styled.button`
  font-size: ${(props) => props.theme.fontxxl};
  color: #fff;
  font-family: inherit;
  font-weight: 800;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;

  &::after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #fff;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  }

  &::before {
    content: "";
    position: absolute;
    top: 80%;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: transparent;
    transition: transform 0.5s;
    transform-origin: center;
  }

  &:focus::after,
  &:hover::after {
    width: 100%;
    left: 0%;
  }

  &:focus, 
  &:hover {
    color: #fff;
  }
`;

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-color: #48735f;
  opacity: 0.6;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  backdrop-filter: blur(5px); /* Optional: Adds a blur effect for better readability */
  z-index: 1;

  @media (max-width: 64em) {
    width: 90%;  /* Adjust width for smaller screens */
  }
`;

const Content = styled.div`
  z-index: 2; /* Ensure content is above the background */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem; /* Space between elements */
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  color: #fff; /* White color for contrast */
  margin-top: 2rem; /* Space between button and title */
`;

const Description = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: #fff; /* White color for contrast */
  margin-top: 1rem; /* Space between title and description */
`;

const Pirkti = () => {
  // Function to handle button click and open the desired URL
  const handleButtonClick = () => {
    window.open('https://termoinzinerija.lt/kontaktai/', '_blank'); // Opens the URL in a new tab
  };

  return (
    <Section id="pirkti">
      <Content>
        {/* Button outside the overlay */}
        <Button onClick={handleButtonClick}>
          Pirkti
        </Button>

        {/* Title and Description inside the overlay */}
        <Overlay>
          <Title>Neomow X</Title>
          <Description>
            Detalesnė pirkimo instrukcija ir sąlygas rasite tolimesniuose žingsniuose.
          </Description>
        </Overlay>
      </Content>
    </Section>
  );
};

export default Pirkti;
